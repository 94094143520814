import type { ToastData, MessageVariant } from "~/types/ui";

export default () => {
  const toast = useState<ToastData | null>("toast", () => null);

  function showToast(
    iconName: string,
    message: string,
    variant: MessageVariant = "info",
    timeout: number | null = null
  ) {
    toast.value = { iconName, message, variant };
    setTimeout(closeToast, timeout ?? 3000); // default to auto-hide after 3 seconds
  }

  function closeToast() {
    toast.value = null;
  }

  return { showToast, closeToast };
};
